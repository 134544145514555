<template>
  <div
    class="info-box"
    :class="hasListeners ? 'clicable' : ''"
    @click="pick(entry.id)"
    :style="cardStyle"
  >
    <span class="info-box-icon" :style="iconBoxStyle">
      <i :class="iconClass" :style="iconStyle" ref="icon">
        <div
          v-if="iconLabel"
          class="info-box-icon-label"
          :title="iconLabel.hint"
          :style="iconLabel.style"
          @click.stop.prevent="iconLabel.onClick && iconLabel.onClick($event)"
        >
          {{ iconLabel.text }}
        </div>
      </i>
    </span>
    <div class="info-box-content">
      <span class="info-box-text" :style="titleStyle">
        <span v-html="titleContent"></span>
      </span>
      <span class="info-box-number" :style="bodyStyle">
        <span v-html="bodyContent"></span>
      </span>
      <div
        class="progress"
        v-if="progress && progress.enabled"
        :style="{
          'background-color': progress.style['background-color'] || '#00000033'
        }"
      >
        <div
          class="progress-bar"
          :style="{
            width: progressValue + '%',
            backgroundColor: progress.color || progress.style.color
          }"
        ></div>
      </div>
      <div class="progress-group" v-else>
        <div class="progress progress-sm">
          <div class="progress-bar bg-gray" style="width: 100%"></div>
        </div>
      </div>
      <div class="text-small">
        <div
          class="card-footer text-small"
          :style="footerStyle"
          :title="footerContent"
        >
          <span v-html="footerContent"></span>
        </div>
      </div>
      <span
        class="big-display"
        v-if="display && display.enabled"
        :style="displayStyle"
        v-html="displayContent"
      ></span>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import StatusIcons from "@/assets/dashboard/status_icons.json";

export default {
  name: "GenericCard",
  props: {
    entry: {
      type: Object,
      required: true,
      default: () => null
    },
    config: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    hasListeners() {
      return this?.$listeners?.pick ? true : false;
    },
    nAlarms: function () {
      return (this.entry.alarms && this.entry.alarms.length) || 0;
    },
    alarms: function () {
      if (this.nAlarms) {
        return this.nAlarms + " Alarms";
      }
      return "no alarm";
    },
    cardStyle() {
      let style = this?.config?.style || {};
      if (this.$root.isDarkTheme) {
        if (style['background-color'] == 'white') {
          style['background-color'] = 'var(--skin-dark-medium)'
        }
      }
      return style;
    },
    iconClass() {
      return this?.config?.icon?.class || "fa fa-question-circle";
    },
    iconBoxStyle() {
      let style = JSON.parse(JSON.stringify(this?.config?.icon?.style || {}));
      let dynamicColor = this?.config?.icon?.dynamicColor || "";
      if (dynamicColor) {
        let color = "inherit";
        if (
          dynamicColor in this.item.icons &&
          this.item.icons[dynamicColor].icon
        ) {
          color = this.item.icons[dynamicColor].icon.color;
        }
        style["background-color"] = color;
      }
      return style;
    },
    iconStyle() {
      let style = {};
      // svg icons make use of mask
      if (this?.config?.icon?.class.indexOf("fa-device") >= 0) {
        let ref = JSON.parse(JSON.stringify(this?.config?.icon?.style || {}));
        style["background-color"] = ref["color"];
      }
      return style;
    },
    iconLabel() {
      // svg icons make use of mask
      if (this?.config?.icon?.label?.text) {
        return {
          text: this.config.icon.label.text,
          hint: this?.config?.icon?.label?.hint ?? "",
          style: {
            color: (this.cardStyle || {})["background-color"] || "black"
          },
          onClick:
            typeof (this?.config?.icon?.label?.click ?? null) == "function"
              ? this?.config?.icon?.label?.click
              : null
        };
      }
      return null;
    },
    item() {
      let item = {
        ...this.entry,
        ...{
          icons: {}
        }
      };
      let lst = this?.config?.statusIcons || [];
      if (!lst) {
        lst = StatusIcons || [];
      }
      (lst || []).forEach((i) => {
        let icon = this.parseIconState(this.entry, i);
        item.icons[i.name] = {};
        item.icons[i.name].icon = icon;
        item.icons[i.name].image = icon
          ? `<i class="${icon.class}" style="color: ${icon.color};"></i>`
          : "";
        item.icons[i.name].text = icon
          ? `<span>${this.$tc(icon.title, 1)}</span>`
          : "";
      });
      return item;
    },
    titleContent() {
      let item = this.item;
      return this.$utils.evaluate(
        item,
        this?.config?.title?.expression || "item.title.expression"
      );
    },
    titleStyle() {
      return this?.config?.title?.style || {};
    },
    footerContent() {
      let item = this.item;
      return this.$utils.evaluate(
        item,
        this?.config?.footer?.expression || "item.footer.expression"
      );
    },
    footerStyle() {
      return this?.config?.footer?.style || {};
    },
    bodyContent() {
      let item = this.item;
      return this.$utils.evaluate(
        item,
        this?.config?.body?.expression || "item.body.expression"
      );
    },
    bodyStyle() {
      return this?.config?.body?.style || {};
    },
    progress() {
      return this?.config?.progress || null;
      //return this?.config?.progress || {expression: "${item.}",color: "#ff0000"} // test
    },
    display() {
      return this?.config?.display || null;
      //return this?.config?.progress || {expression: "${item.}",color: "#ff0000"} // test
    },
    displayContent() {
      let item = this.item;
      return this.$utils.evaluate(
        item,
        this?.config?.display?.expression || "item.display.expression"
      );
    },
    displayStyle() {
      return this?.config?.display?.style || {};
    },
    progressValue() {
      let item = this.item;
      let value = this.$utils.evaluate(
        item,
        this?.config?.progress?.expression || "item.progress.value"
      );
      return isNaN(Number(value)) ? 0 : parseFloat(value);
    },
    hasOptions() {
      return Object.keys(this.$slots || {}).length > 0;
    },
    bigDisplayColor() {
      return this?.bodyStyle?.color || "#fff";
    }
  },
  methods: {
    pick: function (value) {
      if (this.hasListeners) {
        this.$emit("pick", value);
      }
    },
    parseIconState(item, statusIcon) {
      if (!(item && statusIcon)) return null;
      switch (statusIcon.name) {
        case "connectorConnection":
          return (
            statusIcon.stateList.find(
              ({value}) => value == item.is_connected
            ) || null
          );

        case "connectorEnabled":
          return (
            statusIcon.stateList.find(({value}) => value == item.enabled) ||
            null
          );

        case "connectorAlarmed":
          return (
            statusIcon.stateList.find(
              ({value}) => value == item.has_active_alarms
            ) || null
          );

        case "connectorEditor":
          return statusIcon.stateList[0];

        case "deviceConnection":
          return (
            statusIcon.stateList.find(
              ({value}) => value == item?.device?.is_connected || false
            ) || null
          );

        case "deviceEnabled":
          return (
            statusIcon.stateList.find(
              ({value}) => value == item?.device?.enabled || false
            ) || null
          );
        case "deviceAlarmed":
          return (
            statusIcon.stateList.find(
              ({value}) => value == item?.device?.has_active_alarms || false
            ) || null
          );

        case "deviceEditor":
          return statusIcon.stateList[0];

        default:
          return null;
      }
    }
  }
};
</script>
<style scoped>
.info-box {
  opacity: 1;
  overflow: hidden;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.info-box-content {
  position: relative;
}

.card-footer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info-box-number {
  font-size: 14px;
  white-space: nowrap;
}
.big-display {
  position: absolute;
  top: 2px;
  right: 10px;
}

.info-box-icon {
  position: relative;
}

.info-box-icon-label {
  cursor: pointer;
  text-shadow: 1px 1px 1px #000;
  font-size: 40%;
  font-weight: 600;
  margin-top: 1em;
  color: #607d8b;
  font-family: sans-serif;
}

@media (min-width: 720px) {
  .info-box-number {
    font-size: 18px;
  }

}

.skin-dark .info-box .progress .progress-bar.bg-gray {
  background: var(--skin-dark-dark) !important;
}
</style>
