<template>
  <div :class="customDivClasses">
    <i :class="customSpinClasses"></i>
  </div>
</template>

<script>
export default {
  name: "Spin",
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: () => false
    },
    divClasses: {
      type: String,
      required: false,
      default: () => 'overlay text-center'
    },
    iconClass: {
      type: String,
      required: false,
      default: () => 'fa-refresh'
    },
    sizeClass: {
      type: String,
      required: false,
      default: () => 'fa-4x'
    }
  },
  computed: {
    customSpinClasses() {
      let classes = 'fa fa-spin';
      classes += ' ' + this.iconClass;
      classes += ' ' + this.sizeClass;
      return classes;
    },
    customDivClasses() {
      let classes = this.divClasses ?? '';
      if (this.inline) classes += ' inline';
      return classes;
    }
  }
};
</script>

<style scoped>
.overlay {
  opacity: 0.5;
}
.skin-dark .overlay {
  opacity: 0.8;
}
.overlay > i {
  color: #666;
}
.skin-dark .overlay > i {
  color: var(--skin-dark-light);
}
.inline {
  display: inline-block;
}
</style>
