<template>
  <transition :name="$attrs.animate ? 'slide' : ''">
    <section class="stats-header-section">
      <div class="stats-header-container" :style="style">
        <div class="row">
          <!-- // TODO: column must be a device screen size configuration instead of bootstrap classes - property name was chosen -->
          <div
            v-for="(item, ix) in filteredCards"
            :key="ix"
            :class="columnClass(ix)"
          >
            <GenericCard :config="cardConfig(item)" :entry="cardInfo(item)">
              <CardFilters v-if="item.showFilter" :filterType="item.type" />
            </GenericCard>
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import GenericCard from "@/components/generic-card.vue";
import CardFilters from "@/components/card-filters.vue";

export default {
  name: "EquipmentSearchQueryCardPanel",
  components: {
    GenericCard,
    CardFilters
  },
  props: {
    source: {
      type: String,
      default: () => "connector", // "device" || "connector"
      required: false
    },
    config: {
      type: Object,
      default: () => null,
      required: false
    }
  },
  data() {
    return {};
  },
  computed: {
    filteredCards() {
      return this.cards.filter(({enabled}) => enabled);
    },
    nCards() {
      return this.filteredCards.length;
    },
    connectionStats() {
      return this.$store.getters["equipment/connectionStats"];
    },
    cards() {
      return this?.config?.cards || [];
    },
    style() {
      return (
        (this.filteredCards.length && this.config && this.config.style) || {}
      );
    }
  },
  methods: {
    cardConfig(item) {
      switch (item.type) {
        case "connection": {
          let stats = this.connectionStats;
          let query = this.$store.getters["equipment/query"];
          if (stats && stats.connected) {
            return {
              ...item.config,
              icon: {
                ...item.config.icon,
                label: {
                  text: stats.connected,
                  hint: this.$tc("connected", stats.connected > 1 ? 2 : 1),
                  click: ($e) => {
                    query = JSON.parse(JSON.stringify(query));
                    query.query_connection.checked = "connected";
                    this.$store.dispatch("equipment/setQuery", query);
                  }
                }
              }
            };
          }
          return item.config;
        }
        case "alarm":
        case "process_area":
          return item.config;
      }
    },
    cardInfo(item) {
      // default statistics
      let stats = this.$store.getters["equipment/stats"];
      let entry = {
        title: "",
        info: "",
        body: "",
        perc: ""
      };
      switch (item.type) {
        case "connection":
          {
            let stats = this.connectionStats;
            entry = {...stats.entry};
            entry.title = this.$tc(stats.source.from, 2);
            entry.info = "";
            entry.body = `${stats.entry.amount}`;
            let tSource = this.$tc(stats.source.from, 2).toLowerCase();
            let tEnabled = this.$tc(
              "enabled",
              stats.enabled > 1 ? 2 : 1
            ).toLowerCase();
            let tDisabled = this.$tc(
              "disabled",
              stats.disabled > 1 ? 2 : 1
            ).toLowerCase();
            let tOf = this.$tc("of", 2, {gender: "o"}).toLowerCase();
            if (stats.query.query_connection.disabled) {
              entry.info = `${tOf} ${stats.available} ${tSource}`;
            } else if (stats.query.query_connection.checked == "all") {
              entry.info = `${stats.enabled} ${tEnabled} / ${stats.disabled} ${tDisabled}`;
            } else if (stats.query.query_connection.checked == "connected") {
              entry.info = `${tOf} ${stats.enabled} ${tSource} ${tEnabled}`;
            } else if (stats.query.query_connection.checked == "disconnected") {
              entry.info = `${tOf} ${stats.enabled} ${tSource} ${tEnabled}`;
            }
          }
          break;
        case "alarm":
          {
            stats = this.$store.getters["equipment/alarmStats"];
            entry = {...stats.entry};
            entry.title = this.$tc("alarm", 2);
            entry.info = `${stats.entry.perc}% ${this.$t("alarmed")}`;
            entry.body = `${stats.entry.amount}`;
          }
          break;
        case "process_area":
          {
            stats = this.$store.getters["equipment/processAreaStats"];
            entry = {...stats.entry};
            entry.title = this.$tc("process_area", 1);
            entry.info = `${stats.entry.amount} ${this.$tc(
              stats.source.from,
              2
            )} (${stats.entry.perc}%)`;
            if (stats.entry.processArea) {
              entry.body = `${stats.entry.processArea.title}`;
            } else {
              entry.body = `${stats.entry.nProcessAreas} ${this.$tc(
                "process_area",
                stats.entry.nProcessAreas > 1 ? 2 : 1
              )}`;
            }
          }
          break;
      }
      return {
        ...stats,
        card_defaults: entry
      };
    },
    columnClass(ix) {
      if (this.nCards > 2) {
        if (this.nCards % 2 && ix == this.nCards - 1) {
          return "col-xs-12 col-sm-12 col-md-4";
        }
        return "col-xs-12 col-sm-6 col-md-4";
      } else if (this.nCards > 1) {
        return "col-xs-12 col-md-6";
      } else {
        return "col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3";
      }
    }
  }
};
</script>

<style scoped>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
</style>
