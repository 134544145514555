import { render, staticRenderFns } from "./spin.vue?vue&type=template&id=239aced2&scoped=true&"
import script from "./spin.vue?vue&type=script&lang=js&"
export * from "./spin.vue?vue&type=script&lang=js&"
import style0 from "./spin.vue?vue&type=style&index=0&id=239aced2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "239aced2",
  null
  
)

export default component.exports