<template>
  <section>
    <div
      style="position: absolute; right: 20px; top: -35px"
      v-if="nExisting > 0"
    >
      <div class="form-group">
        <label for="overwrite" class="checkbox no-select clicable">
          <input
            type="checkbox"
            id="overwrite"
            class="input-control"
            v-model="overwrite"
          />{{ $t("titles.overwrite_n_items", {count: nExisting}) }}
        </label>
      </div>
    </div>
    <div
      id="drop-area"
      class="drop-area"
      :class="dragging ? 'dragging' : ''"
      @dragover.stop.prevent="dragging = true"
      @dragleave.stop.prevent="dragging = false"
      @drop.stop.prevent="handleFiles($event.dataTransfer.files)"
      :style="{'align-content': enabled ? '' : 'center'}"
    >
      <div class="overlay overlay-loading" v-if="busy">
        <i class="fa fa-refresh fa-spin"></i>
      </div>
      <label class="btn" for="fileElem" v-if="!enabled">{{
        $t("drag_file")
      }}</label>
      <!-- begin gallery -->
      <div
        class="gallery no-select"
        @dragend.stop.prevent
        @dragstart.prevent
        @dragover.stop.prevent
        @dragleave.stop.prevent
      >
        <template v-for="(item, ix) in list">
          <span v-if="item.loaded" :key="ix">
            <span
              class="btn btn-xs"
              :title="$t('remove')"
              @click.stop.prevent="removeAt(ix)"
              ><i class="fa fa-trash"></i
            ></span>
            <img
              :src="item.blob"
              :class="item.img && !overwrite ? 'disabled' : ''"
              :title="
                item.img && !overwrite
                  ? $t('titles.item_already_exists', {name: item.name})
                  : item.name
              "
            />
          </span>
        </template>
      </div>
      <!-- end gallery -->
      <input
        type="file"
        id="fileElem"
        multiple
        accept="image/*"
        style="display: none"
        @change="handleFiles($event.target.files)"
      />
    </div>
    <div class="message-box">
      <div class="text-danger text-center" v-if="errorMessage">
        <i class="fa fa-exclamation-triangle"></i> {{ errorMessage }}
      </div>
    </div>
    <div class="footer" style="position: relative; clear: both">
      <button
        class="btn btn-sm btn-default"
        @click.stop.prevent="!busy && $emit('close')"
        :disabled="busy"
      >
        <i class="fa fa-arrow-left"></i>
        {{ $t("cancel") }}
      </button>
      <button
        class="btn btn-sm btn-default"
        :disabled="!enabled || busy"
        @click.stop.prevent="busy && reset()"
      >
        <i class="fa fa-eraser"></i>
        {{ $t("clear") }}
      </button>
      <button
        class="btn btn-sm btn-primary"
        :disabled="!enabled || waitTime > 0 || busy"
        @click.stop.prevent="upload"
      >
        <i class="fa fa-upload"></i>
        {{ $t("upload") }} {{ (list.length && `[${list.length}]`) || "" }}
      </button>
    </div>
  </section>
</template>

<script>
const initialState = () => ({
  dragging: false,
  busy: false,
  list: [],
  waitTime: 0,
  overwrite: true
});
export default {
  name: "ImageUploadForm",
  props: {
    libraryId: {
      type: Number,
      default: null,
      required: false
    }
  },
  components: {},
  data: initialState,
  computed: {
    enabled() {
      return (this?.list || []).length > 0;
    },
    imageList() {
      return this.$store.getters["synoptic/images"];
    },
    nExisting() {
      return (this.list || []).filter(({img}) => (img ? true : false)).length;
    },
    errorMessage() {
      return this.waitTime
        ? this.$t("too_many_requests") +
            " " +
            this.$t("please_wait", {timeout: ` ${this.waitTime}s`})
        : "";
    }
  },
  methods: {
    handleFiles(files) {
      this.dragging = false;
      let lst = Array.from(files).filter(
        (file) =>
          file.size &&
          file.type &&
          /^image\//.test(file.type) &&
          file.name &&
          file.size / 1048576 < 1
      );
      if (!lst.length) {
        this.$utils.notifyUser(this, this.$t("invalid_image"), {
          type: "error",
          icon: ""
        });
        return;
      }
      this.list = (this.list || [])
        .filter(({name}) => !lst.some((file) => file.name == name))
        .concat(lst.map((i) => ({name: i.name, file: i})));
      let c = this.list.length;
      this.busy = true;
      this.list.forEach((item) => {
        item.img =
          (item &&
            (this.imageList || []).find(
              ({name, images_library_id}) =>
                parseInt(images_library_id) === this.libraryId &&
                name === item?.name
            )) ||
          null;
        let reader = new FileReader();
        reader.onload = (e) => {
          c--;
          item.blob = e.target.result;
          this.$set(item, "loaded", true);
          if (!c) this.busy = false;
        };
        reader.readAsDataURL(item.file);
      });
    },
    reset() {
      let data = initialState();
      for (var p in data) {
        this[p] = data[p];
      }
      if (this._timer) {
        clearInterval(this._timer);
        this._timer = null;
      }
    },
    upload() {
      let updated_at = new Date()
        .toISOString()
        .replace(/\D/g, "")
        .substring(0, 14);
      let tasks = (this.list || []).map(({file, img}) => {
        if (img) {
          if (this.overwrite) {
            return this.$store.dispatch("synoptic/updateImage", {
              description: `${img.description}\nupdated_at: ${updated_at}`,
              images_library_id: this.libraryId,
              path: file
            });
          } else {
            return new Promise((r) => r());
          }
        } else {
          return this.$store.dispatch("synoptic/uploadImage", {
            description: "",
            images_library_id: this.libraryId,
            path: file
          });
        }
      });
      this.busy = true;
      Promise.allSettled(tasks).then((lst) => {
        let minTime;
        lst.forEach((task, ix) => {
          if (task.status == "fulfilled") {
            this.list[ix].loaded = false;
            this.list[ix].blob = null;
          } else if (task?.reason?.message) {
            if (/\d+ se/.test(task?.reason?.message)) {
              try {
                let w = parseInt(
                  task?.reason?.message.match(/\d+ se/)[0].replace(/\D+/, "")
                );
                minTime = minTime === undefined ? w : w < minTime ? w : minTime;
              } catch (e) {}
            }
          }
        });
        this.waitTime = minTime;
        if (this.waitTime) {
          this._timer = setInterval(() => {
            this.waitTime--;
            if (this.waitTime <= 0 || !this.list.length) {
              clearInterval(this._timer);
              this._timer = null;
            }
          }, 1000);
        }
        this.list = this.list.filter(({loaded}) => loaded);
        this.busy = false;
        if (this.list.length) return;
        this.$emit("close");
      });
    },
    removeAt(ix) {
      this.list.splice(ix, 1);
    }
  }
};
</script>

<style scoped>
section {
  padding: 10px;
  clear: both;
  position: relative;
  height: calc(100% - 60px);
  width: 100%;
}

.drop-area {
  border: 3px dashed #ccc;
  border-radius: 10px;
  width: 100%;
  height: calc(100% - 60px);
  text-align: center;
  margin: 0 auto;
  overflow: auto;
  position: relative;
}

.skin-dark .drop-area {
  border: 3px dashed var(--skin-dark-light);
}

.drop-area.dragging {
  border-color: black;
}

.gallery > span {
  position: relative;
  min-height: 80px;
  display: inline-block;
}

.gallery > span > span.btn {
  position: absolute;
  top: 0;
  right: 0;
  color: red;
  font-size: 110%;
}

.skin-dark .gallery > span > span.btn {
  background-color: var(--skin-dark-medium);
  color: var(--skin-dark-light);
}

.skin-dark .gallery > span > span.btn:hover {
  background-color: var(--skin-dark-semilight);
  color: #fff;
}

.gallery > span > img {
  width: 120px;
  margin: 5px;
  border-radius: 3px;
}

.gallery > span > img.disabled {
  opacity: 0.4;
}

.message-box {
  position: relative;
  height: 24px;
  max-height: 24px;
  width: 100%;
  margin: 10px 0 14px 0;
}

.message-box > div {
  text-overflow: ellipsis;
  line-height: 0.8em;
}

.footer {
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0;
}

.footer > .btn:not(:last-child) {
  margin-right: 0.5rem;
}

.footer > .btn {
  flex: 1;
}

button {
  width: 100%;
}

#upload-btn,
#clear-btn {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

#upload-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.overlay-loading {
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 80px;
  text-align: center;
  margin-top: 2%;
  color: #607d8b;
  width: 100%;
  padding: 80px;
  z-index: 1;
}
</style>